import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BigNumber from "bignumber.js";

import Layout from "../../components/Layout"
import TextRainbow from "../../components/TextRainbow"

import ethLogo from "../../images/eth-logo.png"
import openseaLogo from "../../images/opensea.png"
import uniswapLogo from "../../images/uniswap.png"

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const InputAreas = styled.div`
    margin: 0 0 2rem 0;
`
const InputArea = styled.div`
    margin: 0.25rem 0;
`
const Label = styled.label`
    width: 250px;
    text-align: right;
    padding: 0 0.25rem 0 0;
`
const Input = styled.input`
    padding: 0.25rem;
    width: 50%;
`
const BoxContainer = styled.div`
    background: #232323;
    width: 250px;
    display: inline-block;
    border-radius: 19px;
    position: relative;
    text-align: center;
    box-shadow: -1px 15px 30px -12px black;
    margin: 50px 10px;
    min-height: 
`
const ImageContainer = styled.div`
    padding-top: 15%;
    position: relative;
    height: 200px;
    margin-bottom: 5px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
`
const GasLogo = styled.div<{logo: string}>`
    background: url(${props => props.logo}) no-repeat center;
    background-size: 100px;
    width: 150px;
    height: 150px;
    opacity: 0.1;
    margin: auto auto;
`
const BoxHeading = styled.div`
    position: relative;
    top: -100px;
`
const BoxDescription = styled.div`
    padding: 20px;
    margin-bottom: 10px;
    font: 14px/20px "Lato", Arial, sans-serif;
    color: #9E9E9E;
`

const Wei = () => {
    const [ethPrice, setEthPrice] = useState(0)
    const [currentWei, setCurrentWei] = useState(0)

    // All units in wei
    const UNITS = [{
        "name": "WEI",
        "units": "1e18",
        "toWei": "1"
    }, 
    {
        "name": "GWEI",
        "units": "1e9",
        "toWei": "1e9"
    }, 
    {
        "name": "ETH",
        "units": "1",
        "toWei": "1e18"
    }]

    const GAS_LIMITS = [{
        "name": "ETH Transfer",
        "gasLimit": 21000,
        "logo": ethLogo
    }, {
        "name": "Standard ERC20 Transfer",
        "gasLimit": 75000,
        "logo": ethLogo
    }, {
        "name": "Standard ERC721 Transfer",
        "gasLimit": 100000,
        "logo": ethLogo
    }, {
        "name": "OpenSea Sale",
        "gasLimit": 200000,
        "logo": openseaLogo
    }, {
        "name": "Uniswap v3 Swap",
        "gasLimit": 185000,
        "logo": uniswapLogo
    }];


    useEffect(() => {
        getEthereumPrice();
      }, []);

    const getEthereumPrice = async() => {
        const ethPrice = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD`)
            .then(response => response.json())
            .then(data => {
                return data.ethereum.usd
            })

        setEthPrice(ethPrice)
    }

    const handleUnitConverter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const value: string = (e.target as HTMLInputElement).value;

        const inputEth: any = (document.getElementById(`input-ETH`) as HTMLInputElement)
        const inputGwei = (document.getElementById(`input-GWEI`) as HTMLInputElement)
        const inputWei = (document.getElementById(`input-WEI`) as HTMLInputElement)

        console.log(value);
        if(RegExp(/^[0-9]+\.?[0-9]*$/).test(value) === false) {
            (e.target as HTMLInputElement).value = "";
            inputEth.value = ""
            inputGwei.value = ""
            inputWei.value = ""
            return;
        }

        const source = (e.target as HTMLInputElement).getAttribute('data-unit-name');

        let ethValue, gweiValue, weiValue;

        switch(source) {
            case 'ETH' :
                ethValue = (value);
                gweiValue = (new BigNumber(value, 10).multipliedBy(1e9).toString(10));
                weiValue = (new BigNumber(value, 10).multipliedBy(1e18).toString(10));
            break;
            case 'GWEI' :
                ethValue = (new BigNumber(value, 10).dividedBy(1e9).toString(10));
                gweiValue = (value);
                weiValue = (new BigNumber(value, 10).multipliedBy(1e9).toString(10));
            break;
            case 'WEI' :
                ethValue = (new BigNumber(value, 10).dividedBy(1e18).toString(10));
                gweiValue = (new BigNumber(value, 10).dividedBy(1e9).toString(10));
                weiValue = (value);
            break;
        }

        inputEth.value = ethValue ?? "0"
        inputGwei.value = gweiValue ?? "0"
        inputWei.value = weiValue ?? "0"

        setCurrentWei(Number(weiValue))

        e.preventDefault();
    }

    return (
      <Layout largeWidthView={false}>
        <Title>
            <Link to="/">~/</Link>
            <Link to="/tools">tools/</Link>&nbsp;Wei
        </Title>
        <SubTitle>
            A calculator to convert between the most used Ethereum units.
        </SubTitle>

        <InputAreas>
        {
            UNITS.map( (unit) => {
                return(
                    <InputArea>
                        <Label>{unit.name}</Label>
                        <Input type="number" id={`input-${unit.name}`} data-unit-name={unit.name} data-units={unit.toWei} onKeyUp={handleUnitConverter} placeholder={unit.name} />
                    </InputArea>
                )
            })
        }
        </InputAreas>

        <SubTitle>
            If you input a gas price value above, it will calculate what it will cost for some operations on Ethereum Mainnet.
            The formula is <code>((wei * gasLimit) / 1e18) * ethPrice</code>
            <p>
                If you broadcast a transaction at <TextRainbow text={(currentWei / 1e9) + ` GWEI`} />, then it will cost you the following for each transaction.
            </p>
        </SubTitle>

        {
            GAS_LIMITS.map( (entry) => {
                return(
                    <BoxContainer>
                        <ImageContainer>
                            <GasLogo logo={entry.logo} />
                            <BoxHeading>
                                {entry.name}
                            </BoxHeading>
                        </ImageContainer>
                        <BoxDescription>
                            GAS COST: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumSignificantDigits: 2, maximumSignificantDigits: 2 }).format( ((currentWei * entry.gasLimit) / 1e18) * ethPrice)} <br />
                            GAS LIMIT: {new Intl.NumberFormat('en-US').format(entry.gasLimit)}
                        </BoxDescription>
                    </BoxContainer>
                )
            })
        }
      </Layout>
    );
  };
  
  export default Wei;